// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	--black: #000000;
	--blue: #006cdc;
	--white: #ffffff;
	--d-grey: #1c1c1e;
	--l-grey: #c7c7c9;
}

.list-option {
	font-family: 'Roboto', sans-serif;
	width: -moz-fit-content;
	width: fit-content;
	padding: 13px 18px;
	border-radius: 100px;
	display: flex;
	align-items: center;
	background-color: var(--d-grey);
}

.audiences-list {
	margin-top: 32px;
}

.audiences-line {
	padding: 0 16px;
	margin-top: 6px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	width: 100%;

	flex-wrap: nowrap;
	overflow-x: auto;
	position: absolute;
	left: 0;
}

.audiences-line {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.audiences-line::-webkit-scrollbar {
	display: none;
}

.floor-audiences {
	margin-bottom: 84px;
}
`, "",{"version":3,"sources":["webpack://./src/components/audiencesList/audiencesList.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,iBAAiB;AAClB;;AAEA;CACC,iCAAiC;CACjC,uBAAkB;CAAlB,kBAAkB;CAClB,kBAAkB;CAClB,oBAAoB;CACpB,aAAa;CACb,mBAAmB;CACnB,+BAA+B;AAChC;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,QAAQ;CACR,WAAW;;CAEX,iBAAiB;CACjB,gBAAgB;CAChB,kBAAkB;CAClB,OAAO;AACR;;AAEA;CACC,qBAAqB;CACrB,wBAAwB;AACzB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[":root {\n\t--black: #000000;\n\t--blue: #006cdc;\n\t--white: #ffffff;\n\t--d-grey: #1c1c1e;\n\t--l-grey: #c7c7c9;\n}\n\n.list-option {\n\tfont-family: 'Roboto', sans-serif;\n\twidth: fit-content;\n\tpadding: 13px 18px;\n\tborder-radius: 100px;\n\tdisplay: flex;\n\talign-items: center;\n\tbackground-color: var(--d-grey);\n}\n\n.audiences-list {\n\tmargin-top: 32px;\n}\n\n.audiences-line {\n\tpadding: 0 16px;\n\tmargin-top: 6px;\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 5px;\n\twidth: 100%;\n\n\tflex-wrap: nowrap;\n\toverflow-x: auto;\n\tposition: absolute;\n\tleft: 0;\n}\n\n.audiences-line {\n\tscrollbar-width: none;\n\t-ms-overflow-style: none;\n}\n\n.audiences-line::-webkit-scrollbar {\n\tdisplay: none;\n}\n\n.floor-audiences {\n\tmargin-bottom: 84px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
