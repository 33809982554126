// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 11vh;
    margin-bottom: 8vh;
}`, "",{"version":3,"sources":["webpack://./src/Loading.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gDAAgC;IAChC,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".loader-container {\n    width: 100vw;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.logo {\n    content: url(../public/logo.svg);\n    width: 11vh;\n    margin-bottom: 8vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
