// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audience {
    font-family: "Roboto", sans-serif;
    background-color: var(--d-grey);
    display: flex;
    flex-direction: column;
    width: -moz-fit-content;
    width: fit-content;
    padding: 13px 16px;
    border-radius: 11px;

    flex: 0 0 auto;
}

.audience-number {
    font-size: 16px;

}

.floor {
    font-family: 'ALS Sector', sans-serif;
}

.time {
    font-size: 10px;
    color: var(--l-grey);
}`, "",{"version":3,"sources":["webpack://./src/components/listItem/listItem.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,+BAA+B;IAC/B,aAAa;IACb,sBAAsB;IACtB,uBAAkB;IAAlB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;;IAEnB,cAAc;AAClB;;AAEA;IACI,eAAe;;AAEnB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".audience {\n    font-family: \"Roboto\", sans-serif;\n    background-color: var(--d-grey);\n    display: flex;\n    flex-direction: column;\n    width: fit-content;\n    padding: 13px 16px;\n    border-radius: 11px;\n\n    flex: 0 0 auto;\n}\n\n.audience-number {\n    font-size: 16px;\n\n}\n\n.floor {\n    font-family: 'ALS Sector', sans-serif;\n}\n\n.time {\n    font-size: 10px;\n    color: var(--l-grey);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
