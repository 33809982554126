// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
	font-family: 'ALS Sector', sans-serif;
	font-size: 16px;
	width: 100%;
	text-align: center;
	background: transparent;
	color: #c7c7c9;
	position: absolute;
	left: 0;
	bottom: 2vh;
}

.footer__link {
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	opacity: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;CACC,qCAAqC;CACrC,eAAe;CACf,WAAW;CACX,kBAAkB;CAClB,uBAAuB;CACvB,cAAc;CACd,kBAAkB;CAClB,OAAO;CACP,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,kBAAkB;CAClB,qBAAqB;CACrB,YAAY;AACb","sourcesContent":[".footer {\n\tfont-family: 'ALS Sector', sans-serif;\n\tfont-size: 16px;\n\twidth: 100%;\n\ttext-align: center;\n\tbackground: transparent;\n\tcolor: #c7c7c9;\n\tposition: absolute;\n\tleft: 0;\n\tbottom: 2vh;\n}\n\n.footer__link {\n\tcursor: pointer;\n\ttext-align: center;\n\ttext-decoration: none;\n\topacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
