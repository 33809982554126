// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
input[type="radio"]{
    display: none;
    appearance: none;
    outline: none;
}

input[type="radio"] + label {
    appearance: none;
    cursor: pointer;
}

input[type="radio"] + label::before {
    background-color: #303030;
    appearance: none;
    justify-self: center;
    align-self: center;
    content: '';
    display: none;
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    border-radius: 54px;
    transition: color 0.5s ease;

}

input[type="radio"]:checked + label {
    appearance: none;
    color: white;
    background-color: #006CDC;
    border-radius: 54px;
    transition: color 0.5s ease;
}

.day_selector {
    margin-top: 20px;
    background-color: #1C1C1E;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 54px;
    transition: all 0.5s ease;
    font-family: 'ALS Sector', sans-serif;
    font-size: 16px;
}

.numerator {
    padding: 14px 20px;

}

.denominator {
    padding: 14px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/myCheckbox/myCheckbox.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;IACV,mBAAmB;IACnB,2BAA2B;;AAE/B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,uBAAkB;IAAlB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,qCAAqC;IACrC,eAAe;AACnB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\ninput[type=\"radio\"]{\n    display: none;\n    appearance: none;\n    outline: none;\n}\n\ninput[type=\"radio\"] + label {\n    appearance: none;\n    cursor: pointer;\n}\n\ninput[type=\"radio\"] + label::before {\n    background-color: #303030;\n    appearance: none;\n    justify-self: center;\n    align-self: center;\n    content: '';\n    display: none;\n    width: 16px;\n    height: 16px;\n    margin: 0;\n    padding: 0;\n    border-radius: 54px;\n    transition: color 0.5s ease;\n\n}\n\ninput[type=\"radio\"]:checked + label {\n    appearance: none;\n    color: white;\n    background-color: #006CDC;\n    border-radius: 54px;\n    transition: color 0.5s ease;\n}\n\n.day_selector {\n    margin-top: 20px;\n    background-color: #1C1C1E;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    color: white;\n    border: none;\n    width: fit-content;\n    border-radius: 54px;\n    transition: all 0.5s ease;\n    font-family: 'ALS Sector', sans-serif;\n    font-size: 16px;\n}\n\n.numerator {\n    padding: 14px 20px;\n\n}\n\n.denominator {\n    padding: 14px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
